import { publicApi } from "@api";
import { deployEnvConfig } from "@configs/deploy-env";
import { delay, fork, put } from "@redux-saga/core/effects";
import preferenceSlice from "@stores/preference/slices.ts";
import logger from "@utils/logger";
import { execQuery } from "@utils/saga";

function* loadServerInfo() {
  const retryDelay = 3000;
  const retryAttempts = 10;
  let attempt = 0;
  let publicInfo = null;

  while (attempt < retryAttempts) {
    publicInfo = yield* execQuery(
      publicApi.endpoints.getInfo.initiate(
        {
          accountId: deployEnvConfig.country.accountId,
        },
        {
          forceRefetch: true,
        },
      ),
    );

    if (publicInfo) {
      return publicInfo;
    }

    attempt++;
    if (attempt < retryAttempts) {
      yield delay(retryDelay);
    }
  }

  return null;
}

function* init() {
  const publicInfo = yield* loadServerInfo();
  logger._console.log("server public info", publicInfo);
  if (publicInfo) {
    yield put(preferenceSlice.actions.setServerInfo(publicInfo));
  }
  yield put(preferenceSlice.actions.setIsLoading(false));
}

export function* preferenceSaga() {
  yield fork(init);
}

export default preferenceSaga;
